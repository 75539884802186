// 基准大小
const baseSize = 16;

// 设置 rem 函数
function setRem() {
  let scale = document.documentElement.clientWidth / 1680;
  if (scale > 1) {
    scale = 1;
  }
  // const scale = document.documentElement.clientWidth / 1920
  document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + "px";
}

// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function() {
  setRem();
};
