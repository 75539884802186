import service from '../utils/request';

// 添加购物车 ,productId,amount
export function addItem(data) {
  return service({
    url: '/cart/addItem',
    method: 'POST',
    params: data
  });
}

// 查询购物车
export function cartDetail() {
  return service({
    url: '/cart/cartDetail',
    method: 'POST'
  });
}

// 删除项目
export function deleteItem(productIds) {
  return service({
    url: '/cart/deleteItem',
    method: 'POST',
    data: {
      productIds: productIds
    }
  });
}

// 提交购物车

// {
//   "contactId": 0,
//   "items": [
//   {
//     "amount": 0,
//     "productId": 0
//   }
// ]
export function submit(data) {
  return service({
    url: '/cart/submit',
    method: 'POST',
    data
  });
}

// 修改购物车
export function modifyItem({ productId, amount }) {
  return service({
    url: '/cart/modifyItem',
    method: 'POST',
    params: {
      productId,
      amount
    }
  });
}
