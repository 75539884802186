import Layout from '@/layout/Index'


const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        redirect: '/home',
        meta: { auth: true }
      },
      {
        path: 'home',
        name: 'home',
        component: () => import('../views/Home.vue'),
        meta: { auth: true }
      },
      {
        name: 'login',
        path: 'login',
        component: () => import('../views/Login.vue'),
        meta: { auth: true }
      },
      {
        path: 'list/:id',
        name: 'goodList',
        component: () => import('../views/GoodList.vue'),
        meta: { auth: true }
      },
      {
        path: 'list-all/:id',
        name: 'goodListAll',
        component: () => import('../views/GoodListAll.vue'),
        meta: { auth: true }
      },
      {
        path: 'list-all-bytag/:id',
        name: 'goodListByTagAll',
        component: () => import('../views/GoodListAllByTag.vue'),
        meta: { auth: true }
      },
      {
        path: 'detail/:id',
        name: 'detail',
        component: () => import('../views/GoodDetail.vue'),
        meta: { auth: true }
      },
      {
        name: 'cart',
        path: 'cart',
        component: () => import('../views/Cart.vue'),
        meta: { auth: true }
      },
      {
        name: 'order',
        path: 'order',
        component: () => import('../views/Order.vue'),
        meta: { auth: true }
      },
      {
        path: 'address',
        component: () => import('../views/Address.vue'),
        meta: { auth: true }
      },
      {
        name: 'orderDetail',
        path: 'orderDetail',
        component: () => import('../views/OrderDetail.vue'),
        meta: { auth: true }
      },
      {
        path: 'projectDetail',
        name: 'project',
        component: () => import('../views/ProjectDetail.vue'),
        meta: { auth: true }
      },
    ]
  },
];


export default routes;
