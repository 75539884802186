<template>
  <div v-if="init" id="app">
    <router-view/>
  </div>
</template>

<script>
import { login } from '@/api/user'
import { checkSession } from '@/api/login'

export default {
  data() {
    return {
      init: false
    }
  },
  async created() {
    await this.$store.dispatch('queryClassTree')
    if (localStorage.getItem('token')) {
      const res = await checkSession();
      if (res.data) {
        // 初始化查询 Class 和购物车
        await this.$store.commit('SET_TOKEN', localStorage.getItem('token'))
        await this.$store.dispatch('queryCart')
      } else {
        // 清除token登录态
        localStorage.removeItem('token')
      }
      this.init = true
    } else {
      this.init = true
    }


  }
}
</script>

<style lang="less">
#app {
  //overflow: hidden;
}

body {
  margin: 0;
}


.is-horizontal {
  display: none;
}

</style>
