<template>

  <div class="cart-add-tips">
    <div class="common-gap"></div>

    <div class="inner">
      <div class="close">
        <svg t="1678509934955" class="icon-close" fill="red" viewBox="0 0 1024 1024" version="1.1"
             xmlns="http://www.w3.org/2000/svg" p-id="4369" width="200" height="200">
          <path d="M872.802928 755.99406 872.864326 755.99406 872.864326 755.624646Z" fill="#272536" p-id="4370"></path>
          <path
            d="M927.846568 511.997953c0-229.315756-186.567139-415.839917-415.838893-415.839917-229.329059 0-415.85322 186.524161-415.85322 415.839917 0 229.300406 186.524161 415.84094 415.85322 415.84094C741.278405 927.838893 927.846568 741.29836 927.846568 511.997953M512.007675 868.171955c-196.375529 0-356.172979-159.827125-356.172979-356.174002 0-196.374506 159.797449-356.157629 356.172979-356.157629 196.34483 0 356.144326 159.783123 356.144326 356.157629C868.152001 708.34483 708.352505 868.171955 512.007675 868.171955"
            fill="red" p-id="4371"></path>
          <path
            d="M682.378947 642.227993 553.797453 513.264806 682.261267 386.229528c11.661597-11.514241 11.749602-30.332842 0.234337-41.995463-11.514241-11.676947-30.362518-11.765975-42.026162-0.222057L511.888971 471.195665 385.223107 344.130711c-11.602246-11.603269-30.393217-11.661597-42.025139-0.059352-11.603269 11.618619-11.603269 30.407544-0.059352 42.011836l126.518508 126.887922L342.137823 639.104863c-11.662621 11.543917-11.780301 30.305213-0.23536 41.96988 5.830799 5.89015 13.429871 8.833179 21.086248 8.833179 7.53972 0 15.136745-2.8847 20.910239-8.569166l127.695311-126.311801L640.293433 684.195827c5.802146 5.8001 13.428847 8.717546 21.056572 8.717546 7.599072 0 15.165398-2.917446 20.968567-8.659217C693.922864 672.681586 693.950494 653.889591 682.378947 642.227993"
            fill="red" p-id="4372"></path>
        </svg>
      </div>
      <div v-if="Item" class="_left">
        <div class="image-box">
          <el-image
            style="width: 100%; height: 100%;border-radius: 12px"
            :src="Item.url"
            :fit="'contain'"></el-image>
        </div>


        <div class="prop-info">
          <div class="title">
            <svg fill="#2BA245" t="1678508445338" class="icon" viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 p-id="2918">
              <path
                d="M997.888 70.144C686.592 261.12 460.8 502.272 358.912 623.104L110.08 428.032 0 516.608l429.568 437.248C503.296 764.416 737.792 394.24 1024 131.072l-26.112-60.928m0 0z"
                p-id="2919"></path>
            </svg>
            Product has been added to your shopping
          </div>
          <div class="prod-name">{{ Item.productName }}</div>
        </div>

      </div>
      <div class="_right">
        <el-button class="btn" @click="close" type="primary">Continue shopping</el-button>
        <el-button class="btn" @click="toCart" type="primary">Review and closing</el-button>
      </div>
    </div>

    <div class="common-gap"></div>

  </div>

</template>

<script>
export default {
  name: 'TipModal',
  methods: {
    close() {
      this.$store.commit('SET_MODAL', false)
    },
    toCart() {
      this.$store.commit('SET_MODAL', false)
      this.$router.push({
        name: 'cart'
      })
    }
  },
  created() {
    const that = this
    setTimeout(() => {
      that.$store.commit('SET_MODAL', false)
    }, 4000)
  },
  computed: {
    Item() {
      if (this.$store.state.cartList.length > 0) {
        return this.$store.state.cartList[this.$store.state.cartList.length - 1].product
      } else {
        return null
      }


    }
  },
  watch: {
    $route() {
      this.$store.commit('SET_MODAL', false)
    }
  }
}
</script>

<style scoped lang="less">

.cart-add-tips {
  box-shadow: 0px 5px 20px 0px rgba(214, 214, 214, 0.2);
  background: #fff;
  width: 100%;
  position: fixed;
  z-index: 5;
  bottom: 10%;
  .inner{
    height: 270px;
    padding: 0 100px 0 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ._left {
    display: flex;
    align-items: center;

    .title {
      font-size: 32px;
    }

    .prop-info {
      margin-left: 40px;

    }

  }

  ._right{
    display: flex;


  }
}

.prod-name {
  white-space: nowrap;
  overflow: hidden;
  max-width: 700px;
  text-overflow: ellipsis;
  font-size: 26px;
}

.image-box {
  width: 300px;
  height: 200px;
}

.icon {
  width: 40px;
  height: 40px;
}

.icon-close {
  width: 40px;
  height: 40px;
}

.close {
  position: absolute;
  right: 40px;
  top: 40px;
}

</style>
