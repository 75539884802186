<template>
  <div class="footer">
    <div class="top">
      <div class="left">
        <div class="title">
          <span class="icon"></span>
          <span>Contact Us</span>
        </div>

        <div class="info">
          <div class="item flex3">
            <span class="icon user"></span>
            <span>Contact: Max Huang</span>
          </div>

          <div class="item flex7">
            <span class="icon email"></span>
            <span>E-mail: max@moducon.cn</span>
          </div>

          <div class="item flex3">
            <span class="icon tel"></span>
            <span>Tel: +86 138 9881 8810  </span>
          </div>

          <div class="item flex7">
            <span class="icon add"></span>
            <span>Add: <span class="f1">Room 1612, F1 Block, No.9 Tawan Street, Huanggu District, Shenyang, Liaoning, P.R. China</span></span>
          </div>
        </div>

      </div>
      <div class="right">
        <img class="logo" src="../assets/images/logo-w.png" alt=""/>
      </div>
    </div>
    <div class="bottom">
<!--      <div>京公网 1100000300000003</div>-->
<!--      <div class="sp"></div>-->
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">辽ICP备16019200号-2</a>
      <div class="sp"></div>
      <div style="display: flex;align-items: center">
        Copyright: Moducon Engineering Co., Ltd.
        <span class="logo-tiny"></span>
      </div>
    </div>

  </div>
</template>


<script>
export default {
  name: "CommonFooter"
};
</script>

<style lang="less" scoped>
.footer {
  font-size: 18px;
  color: #ffffff;

  .f1{
    font-size: 1rem;
  }

  .top{
    padding: 31px 75px;
    background: #43328F;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left{
      display: flex;
      align-items: center;

      .title{
        margin-right: 60px;
        display: flex;
        align-items: flex-end;
        .icon{
          background: url("~@/assets/images/smils@2x.png") no-repeat center;
          background-size: contain;
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
      }

      .info{
        border-left: 2px solid #fff;
        padding-left: 60px;
        flex: 1;
        display: flex;
        flex-wrap: wrap;

        .item{
          height: 40px;
          display: flex;
          align-items: center;
          line-height: 40px;
          .icon{
            width: 25px;
            height: 25px;
            margin-right: 25px;
            margin-bottom: 5px;
            &.user{
              background: url("~@/assets/images/contact@2x.png") no-repeat center;
              background-size: contain;
            }
            &.email{
              background: url("~@/assets/images/e-mail@2x.png") no-repeat center;
              background-size: contain;
            }
            &.tel{
              background: url("~@/assets/images/telephone@2x.png") no-repeat center;
              background-size: contain;
            }
            &.add{
              background: url("~@/assets/images/address@2x.png") no-repeat center;
              background-size: contain;
            }
          }
        }
        .flex3{
          flex: 0 0 25%;
        }
        .flex7{
          flex: 0 0 75%;
        }
      }

    }
    .right{
      .logo{
        width: 164px;
        height: 26px;
      }
    }
  }

  .bottom{
    //padding: 0 500px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-color-blue);

    a{
      color: var(--main-color-blue);
      &:hover{
        text-decoration: underline;
      }
    }

    .sp{
      flex: 0 0 2px;
      min-width: 2px;
      width: 2px;
      height: 18px;
      margin: 0 20px;
      background: var(--main-color-blue);
    }

    .logo-tiny{
      margin-left: 20px;
      display: inline-block;
      width: 40px;
      height: 40px;
      background: url("~@/assets/images/logo_40x40.png") no-repeat center;
      background-size: contain;
    }
  }

  .title {
    font-size: 20px;
    font-family: Poppins-Medium, sans-serif;
  }
}
</style>
