import Vue from 'vue';
import Vuex from 'vuex';
import { addItem, cartDetail, deleteItem, modifyItem, submit } from '@/api/cart';
import { getAllProductClaasify } from '@/api/home';
import XEUtils from 'xe-utils'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: '',
    cartList: [],// 购物车
    classTree: null, // []
    loading: false, // 是否处于全局加载
    showModal: false, // 是否展示添加商品modal
  }, mutations: {
    SET_TOKEN: (state, flag) => {
      state.token = flag;
    },
    SET_MODAL: (state, flag) => {
      state.showModal = flag;
    },
    SET_LOADING: (state, flag) => {
      state.loading = flag;
    },
    SET_CLASS_TREE: (state, data) => {
      state.classTree = data;
    }, // 加入购物车
    // PUSH_CART: (state, good) => {
    //   good.count = 1;
    //   state.cartList.push(good)
    // },
    // 加入购物车
    SET_CART: (state, list) => {
      state.cartList = list;
    }, // 删除商品
    // DEL_CART_BY_ID: (state, good) => {
    //   state.cartList.filter((item) => item.id !== good.id);
    // }, // 修改商品数量
    // CHANG_GOOD_COUNT: (state, {
    //   good, num
    // }) => {
    //   const tg = state.cartList.find((item) => item.id === good.id);
    //   tg.count = num;
    //   if (tg.count === 0) {
    //     this.DEL_CART_BY_ID(good.id);
    //   }
    // }
  }, getters: {
    GET_GOOD: function () {
      return function (state, good) {
        const tg = state.cartList.find((item) => Number(item.id) === Number(good.id));
        if (tg) {
          return tg.count;
        } else {
          return 0;
        }
      };
    }, // 根据classID 获取 tree 的list
    GET_TREE_LIST_BY_CLASS_ID: function (state) {
      return function (id) {
        //寻找node
        console.log('寻找参数:', id)
        console.log('state.classTree:', state.classTree)
        const tgTree = XEUtils.findTree(state.classTree, item => Number(item.id) === Number(id), { children: 'subProductClassify' });
        console.log('Find Tree | :', tgTree)
        return tgTree
      };
    }
  },
  actions: {
    GET_TREE_LIST_BY_CLASS_ID(ctx, id) {

      const that = this

      return new Promise(function (resolve, reject) {


        try {
          //寻找node
          console.log('寻找参数:', id)
          console.log('tree:', that.state.classTree);
          if (that.state.classTree) {
            const tgTree = XEUtils.findTree(that.state.classTree, item => Number(item.id) === Number(id), { children: 'subProductClassify' });
            console.log('Find Tree | :', tgTree)
            resolve(tgTree)
            // return tgTree
          } else {
            getAllProductClaasify().then(function (res) {
              ctx.commit('SET_CLASS_TREE', res.data);
              const tgTree = XEUtils.findTree(that.state.classTree, item => Number(item.id) === Number(id), { children: 'subProductClassify' });
              console.log('Find Tree | :', tgTree)
              resolve(tgTree)
            })
          }
        } catch (e) {
          console.error(e)
        }


      })


    }, queryClassTree(ctx) {
      return new Promise((resolve) => {
        if (this.state.classTree) {
          resolve(this.classTree)
        } else {
          getAllProductClaasify().then((res) => {
            ctx.commit('SET_CLASS_TREE', res.data);
            resolve(this.state.classTree)
          })
        }
      })
    }, // 下单
    async submit({ dispatch }, payLoad) {
      await submit(payLoad);
      dispatch('queryCart');
    },
    // 添加商品
    async addItem({ dispatch }, payLoad) {
      await addItem(payLoad);
      dispatch('queryCart');
    },
    // 删除商品
    async deleteItem({ dispatch }, payLoad) {
      await deleteItem(payLoad);
      dispatch('queryCart');
    },
    // 修改商品
    async modifyItem({ dispatch, commit }, payLoad) {
      await modifyItem(payLoad);
      dispatch('queryCart');
    },
    // 查询购物车
    async queryCart({ commit }) {
      if (localStorage.getItem('token')) {
        const res = await cartDetail();
        console.log('查询购物车：', res);
        res.data = res.data.map(item => {

          if (item.product.productImageUrl) {
            const list = item.product.productImageUrl.split(',');
            if (list && list.length > 0) {
              item.product.url = list[0];
            }
          } else {
            item.product.url = '';
          }

          return item;

        });
        commit('SET_CART', res.data);
        return this.cartList;
      }
    }
  }, modules: {}
});
