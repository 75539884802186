import service from "../utils/request";

export function checkSession() {
  return service({
    url: '/checkToken',
    method: 'POST'
  })
}

export function toResetPassword(data) {
    return service({
        url: '/resetPassword',
        method: 'POST',
        data: data
    })
}

export function toSignup(data) {
    return service({
        url: '/signup',
        method: 'POST',
        data: data
    })
}
export function verifyCode(email) {
    return service({
        url: `/verifyCode?email=${email}`,
        method: 'POST',
    })
}
export function toLogin(data) {
    console.log(data);
    return service({
        url: '/login/auth',
        method: 'POST',
        data: data
    })
}