import service from '../utils/request';


export function getVideo() {
  return service({
    url: '/Cfront/findVedio',
    method: 'POST',
  })
}

export function getBanner(data) {
  return service({
    url: 'Cfront/bannerInfolist',
    method: 'POST',
    data: data,
  })
}

export function getAllProductClaasify() {
  return service({
    url: '/Cfront/getAllProductClaasify',
    method: 'POST',
  })
}