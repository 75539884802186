import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store'
import routes from "./routers"

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 路由卫士
router.beforeEach((to, from, next) => {
  if (to.path === '/cart' || to.path === '/order' || to.path === '/address' || to.path === 'orderDetail' || to.path === 'projectDetail') {
    window.localStorage.getItem('token') ? next() : next("/login")
  }
  next()
})


export default router
