<template>
  <div class="main-content">
    <Header/>
    <el-scrollbar ref="scroll" class="scroll-bar">
      <Main fullscreen v-loading="Loading"/>
      <Footer/>
    </el-scrollbar>
  </div>
</template>

<script>
import Header from '@/layout/Header';
import Main from '@/layout/Main';
import Footer from '@/layout/Footer';

export default {
  components: { Header, Main, Footer },

  mounted() {
  },
  computed: {
    Route(){
      return this.$route
    },
    Loading() {
      return this.$store.state.loading
    }
  },
  watch:{
    Route(){
      this.$refs.scroll.wrap.scrollTop = 0
    }

  }
};
</script>

<style lang="less" scoped>

.main-content{
  overflow-x: hidden;
}
.scroll-bar {
  height: calc(100vh - 120px);
}

/deep/.el-scrollbar__wrap{
  overflow-x: hidden;
}
</style>
