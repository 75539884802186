<template>
  <div class="menu-content">
    <div class="lv1 list">
      <div @click="lvInter(item,3)" :class="{active:currentLv1===item.id}" :key="item.id" class="item"
           v-for="item in classList">
        <span>{{ item.classifyName }}</span>
        <i @click.stop="lvInter(item,1)" v-if="item.subProductClassify" class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div v-show="lv2.length>0" class="lv2 list">
      <div @click="lvInter(item,3)" :class="{active:currentLv2===item.id}" :key="item.id" class="item"
           v-for="item in lv2">
        <span>{{ item.classifyName }}</span>

        <i @mouseenter.stop="null" @click.stop="lvInter(item,2)" v-if="item.subProductClassify" class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div v-show="lv3.length>0" class="lv3 list">
      <div @click="lvInter(item,3)" :key="item.id" class="item" v-for="item in lv3">{{ item.classifyName }}</div>
    </div>

  </div>
</template>

<script>
import { getAllProductClaasify } from '@/api/home'

export default {
  name: 'Menu',
  data() {
    return {
      classList: {},
      lv2: [],
      lv3: [],
      currentLv1: '',
      currentLv2: '',
    };
  },
  methods: {
    init() {
      this.lv2 = []
      this.lv3 = []
    },
    lvInter(item, level) {
      if (item.subProductClassify) {
        switch (level) {
          case 1:
            this.lv2 = item.subProductClassify;
            this.currentLv1 = item.id
            this.currentLv2 = ''
            this.lv3 = []
            break
          case 2:
            this.lv3 = item.subProductClassify;
            this.currentLv2 = item.id
            break
          case 3:
            this.$router.push({
              name: 'goodListAll',
              params: {
                id: item.id,
              },
            });
            this.$emit('close')
        }
      } else {
        this.$router.push({
          name: 'goodListAll',
          params: {
            id: item.id,
          },
        });
        this.$emit('close')
      }
    }
  },
  mounted() {
    getAllProductClaasify().then((res) => {
      console.log(res);
      this.classList = res.data;
    });
  },
}
</script>

<style lang="less" scoped>

.menu-content {
  display: flex;
  align-items: stretch;
  background: #F4F8FF;

  .list {
    min-width: 330px;

    .title {
      font-size: 32px;
      font-family: Poppins-Bold;
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //padding: 0 20px;
      padding-left: 20px;
      font-size: 26px;
      height: 70px;
      line-height: 70px;
      background: #F4F8FF;
      color: var(--main-color-blue);
      cursor: pointer;

      > span {
        margin-right: 20px;
      }

      .el-icon-arrow-right{
        height: 100%;
        display: flex;
        align-items: center;
        //background: red;
        padding-right: 20px;
        padding-left: 20px;
        &:hover {
          //font-family: Poppins-Bold;
          //color: red;
          &:before{
            content: "\e6dc";
          }
        }
      }

      &.active, &:hover {
        //font-family: Poppins-Bold;
        color: #fff;
        background: var(--main-color-blue);
      }
    }
  }
}

</style>