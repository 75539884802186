import service from '../utils/request';

export function login(data) {
  return service({
    url: '/login/auth', method: 'POST', data: data
  })
}

// 添加地址
export function addContact(data) {
  return service({
    url: '/user/addContact', method: 'POST', data: data
  })
}

// 查询地址列表
export function address() {
  return service({
    url: '/user/addresses', method: 'POST',
  })
}