<template>
  <div class="main">
    <router-view />
    <TipModal v-if="$store.state.showModal"></TipModal>
  </div>
</template>

<style lang="less" scoped>
.main {
  min-height: calc(100vh - 200px);
}
</style>
<script>
import TipModal from '@/components/TipModal'
export default {
  components: { TipModal }
}
</script>