<template>
  <div class="header">
    <div class="menu">

      <el-popover
        @hide="hide"
        :visible-arrow="false"
        placement="bottom"
        v-model="visible"
        trigger="hover">
        <div slot="reference" class="menu-title">
          <img src="../assets/images/more.png" alt=""/>Products
        </div>
        <Menu ref="menu" @close="close"></Menu>

      </el-popover>


      <!--        class="el-menu"-->
      <!--        mode="horizontal"-->
      <!--        :collapse-transition="false"-->
      <!--        @select="handleSelect"-->
      <!--      >-->
      <!--        <el-submenu index="1-1" class="outer">-->
      <!--          <template slot="title"-->
      <!--            ><span class="menu-title"-->
      <!--              ><img src="../assets/images/more.png" alt="" />Products</span-->
      <!--            ></template-->
      <!--          >-->
      <!--          <div v-for="item in classList" :key="item.id.toString()">-->
      <!--            <el-submenu-->
      <!--              :index="item.id.toString()"-->
      <!--              v-if="-->
      <!--                item.subProductClassify && item.subProductClassify.length > 0-->
      <!--              "-->
      <!--              class="menu-item"-->
      <!--            >-->
      <!--              <template slot="title">{{ item.classifyName }}</template>-->
      <!--              <MenuTree :list="item.subProductClassify"></MenuTree>-->
      <!--            </el-submenu>-->
      <!--            <el-menu-item v-else :index="item.id.toString()" class="menu-item">-->
      <!--              <span slot="title">{{ item.classifyName }}</span>-->
      <!--            </el-menu-item>-->
      <!--          </div>-->
      <!--        </el-submenu>-->
      <!--      </el-menu>-->
    </div>

    <div @click="toHome" class="center" style="cursor: pointer">
      <img src="../assets/images/logo@2x.png" alt=""/>
    </div>
    <div class="right">
      <img
        v-if="!Token"
        class="icon-user"
        @click="toPage('order')"
        src="../assets/images/login.png"
        alt=""
      />
      <el-dropdown @command="clickMenu" v-if="Token" :trigger="'click'" :placement="'bottom'">
        <span class="el-dropdown-link">
          <img
            class="icon-user"
            src="../assets/images/login.png"
            alt=""
          />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="order">My Order</el-dropdown-item>
          <el-dropdown-item command="logOut">Log Out</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>


      <span class="icon-order">
        <img @click="toPage('cart')" src="../assets/images/cart.png" alt=""/>
        <span v-if="CartNum" class="num">{{ CartNum }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import { getAllProductClaasify } from '@/api/home';
// import MenuTree from "../components/MenuTree.vue";
import Menu from '@/components/Menu'

export default {
  components: { Menu },
  data() {
    return {
      classList: {},
      visible: false,
      token:'',
    };
  },
  created() {
    let that = this;
    that.token =localStorage.getItem('token')
  },
  methods: {
    hide() {
      this.$refs.menu.init()
    },
    close() {
      this.visible = false
    },
    toHome() {
      this.$router.push({
        name: 'home'
      });
    },
    handleSelect(key, keyPath) {
      this.$router.push({
        name: 'goodListAll',
        params: {
          id: key,
        },
      });
    },
    toPage(name) {
      // 判断没登陆，去登陆
      if (!window.localStorage.getItem('token')) {
        if (this.$route.path !== '/login') {
          localStorage.setItem('fromPath', this.$route.path);
        }
        this.$router.push({
          name: 'login',
        });
      } else {
        this.$router.push({
          name,
        });
      }
    },
    clickMenu(command) {
      if (command === 'order') {
        this.$router.push({
          name: 'order',
        });
      } else {
        localStorage.removeItem('token')
        this.$router.push({
          name: 'login',
        });
      }
    }
  },
  computed: {
    Token() {
      // return localStorage.getItem('token')
      return this.$store.state.token
    },
    CartNum() {
      return this.$store.state.cartList.length;
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  height: 120px;
  padding: 46px 12.5px 46px 20.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F9F9F9;
  color: #ffffff;

  .menu {
    flex: 0 0 500px;
  }

  /deep/ .el-submenu__icon-arrow {
    &::before {
      display: none !important;
    }
  }

  /deep/ .el-submenu__title {
    &:hover {
      background-color: #F9F9F9 !important;
    }
  }

  ::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title a {
    color: white;
    height: 100%;
  }

  ::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title:hover a {
    color: #ffffff !important;
  }

  ::v-deep .el-submenu.is-active .el-submenu__title {
    border: none;
  }

  /deep/ .el-menu.el-menu--horizontal {
    border-bottom: none;
  }

  .menu-title {
    cursor: pointer;
    background-color: #F9F9F9;
    font-size: 28px;
    font-weight: 400;
    width: 200px;
    color: var(--main-color-blue);

    img {
      width: 28px;
      margin-right: 16px;
    }
  }

  .el-menu {
    &::after {
      display: none;
    }

    &::before {
      display: none;
    }

    .outer {
      height: 120px;
      display: flex;
      align-items: center;
      margin-left: -25px;
      padding-left: 25px;
    }
  }

  .center {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-weight: 400;
    color: #ffffff;

    img {
      width: 254px;
      //margin-right: 23px;
    }

    span {
      margin-top: 10px;
    }
  }

  .right {
    flex: 0 0 500px;
    text-align: right;

    img {
      height: 28px;
      cursor: pointer;
    }

    .icon-order {
      margin-left: 52px;
      margin-right: 40px;

      position: relative;

      .num {
        right: -20px;
        top: -14px;
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: red;
        overflow: hidden;
        border-radius: 100%;
        display: inline-block;
        text-align: center;
        line-height: 20px;
        font-size: 16px;
      }
    }
  }
}

.icon-user {
}

.icon-order {
}
</style>
<style lang="less">
.menu-item {
  width: 326px !important;
  height: 70px !important;
  font-size: 26px !important;
  font-weight: 400 !important;
  color: #43328F !important;
  line-height: 70px !important;
  background-color: #f4f8ff !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;

  .el-submenu__title {
    font-size: 26px !important;
    font-weight: 400 !important;
    height: 70px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    color: #43328F !important;
    line-height: 70px !important;
    background-color: #f4f8ff !important;

    &:hover {
      color: #ffffff !important;
      background-color: #43328F !important;
    }
  }

  &:hover {
    color: #ffffff !important;
    background-color: #43328F !important;
  }
}

.el-menu {
  background-color: #f4f8ff !important;
}
</style>
