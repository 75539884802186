import request from "axios"
import { Message } from './resetMessage'
import router from '../router/index'
import store from '@/store'



const service = request.create({
    baseURL: '/coco',
    timeout: 50000,
    headers: { 'Content-Type': 'application/json' }
});

service.interceptors.request.use(function (config) {
  store.commit('SET_LOADING',true)
  const token = localStorage.getItem("token")
  config.headers.token = token
  return config;
}, function (error) {
  return Promise.reject(error);
});

service.interceptors.response.use(
    response => {


        store.commit('SET_LOADING',false)
        const res = response.data;

        //判断response状态
        if (res.code !== 200) {
            Message({
                message: `${res.message}`,
                type: "error",
            })
            if ([20011,505].includes(res.code)) {
                localStorage.removeItem('token');
                router.replace({
                    path: '/login'
                })
            }
            return Promise.reject(res)
        };

        return res
    },

    error => {
        Message({
            message: error,
            type: "error",
        })
        // router.push('/500')
        return Promise.reject(error)
    }
);

export default service
